<template>
  <div class="wrapper ion-text-center">
    <div class="img-wrapper">
      <ion-img :src="drawerFill" alt="drawer" />
    </div>
    <div class="ion-margin-top ion-margin-bottom">
      <ion-label>{{ boardingLabel }}</ion-label>
    </div>
    <div v-if="currentStep === 1" class="text-center">
      <ion-text class="ion-text-center fw-600">
        {{ $t('register_your_company') }}
      </ion-text>
    </div>
    <ion-button :fill="btnFill" class="ion-margin-top" @click="onAction">
      <ion-icon v-if="currentStep === 1" :icon="addOutline" color="light"></ion-icon>
      {{ btnWording }}
      <ion-icon style="margin-left: 10px" v-if="currentStep === 2" :icon="wa"></ion-icon>
    </ion-button>
    <ion-text @click="$emit('refreshAct')" class="ion-text-center fw-500 center-align" color="primary">
      <ion-icon :icon="refresh_icon"></ion-icon>
      {{ $t('refresh') }}
    </ion-text>
  </div>
</template>
<script>
import drawer_not_found from '@/assets/icons/shared/drawer_not_found.svg';
import drawer_welcome from '@/assets/icons/shared/drawer_welcome.svg';
import refresh_icon from '@/assets/icons/shared/refresh.svg';
import wa from '@/assets/icons/shared/wa.svg';
import { addOutline, arrowForwardOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BoardingState',
  props: {
    currentStep: {
      type: Number,
      default: 1
    }
  },
  emits: ['refreshAct'],
  setup() {
    return {
      drawer_not_found,
      drawer_welcome,
      arrowForwardOutline,
      addOutline,
      refresh_icon,
      wa
    };
  },
  computed: {
    drawerFill() {
      return drawer_not_found;
    },
    boardingLabel() {
      return this.currentStep === 1 ? this.$t('you_have_no_company') : this.$t('we_are_reviewing_company');
    },
    btnWording() {
      return this.currentStep === 1 ? this.$t('add_new_company') : this.$t('contact_us');
    },
    btnFill() {
      return this.currentStep === 1 ? 'solid' : 'outline';
    }
  },
  methods: {
    onAction() {
      this.currentStep === 1
        ? this.$router.push('/sale/customers/add/no-companies')
        : window.open('https://wa.me/6588353386', '_blank');
    }
  }
});
</script>
<style lang="scss" scoped>
.wrapper {
  width: 85%;
  margin: 0 auto;
  top: 20%;
  position: relative;
  .img-wrapper {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.text-center {
  text-align: center;
}
ion-label {
  font-size: 1.2rem;
  color: #000;
  font-weight: bold;
}
ion-button {
  border-radius: 10px;
  text-transform: none;
  width: 100%;
}

ion-icon {
  font-size: 20px;
  margin-bottom: -5px;
}
.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>
